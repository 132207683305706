<template>
  <div>
    <div class="top">
      <span class="name">{{ kpiInfo.activities_kpi_name ? kpiInfo.activities_kpi_name : "-" }}</span>
      <span class="time">{{ start_time || "-" }} 至 {{ end_time || "-" }}</span>
    </div>


    <p class="main-title1">活动模板设置</p>
    <a-checkbox v-model:checked="modal_checked" disabled style="padding-left: 100px" @change="open_type = 0">
      是否使用模板
    </a-checkbox>
    <div style="margin-top: 20px">
      <span>模板限制：</span>
      <a-radio-group
          v-model="open_type"
          disabled
      >
        <a-radio :value="0"> 不限制</a-radio>
        <a-radio :value="1"> 指定模板</a-radio>
      </a-radio-group>
      <span v-if="selectedRowKeys.length">
           <a-button type="link" style="margin-right: 10px">已选择{{ selectedRowKeys.length || 0 }}个模板</a-button>
          <a-icon type="file-search" style="font-size: 22px;cursor: pointer" @click="handelBtn"/>
         </span>
    </div>

    <div class="top">
      <span class="KPI_name">活动KPI</span>
      <span class="time">({{ kpiValue.length }}个指标)</span>
    </div>
    <a-table
        :columns="activityColumnsList"
        :data-source="kpiValue"
        :pagination="false"
        :rowKey="(record, index) => index"
    >
      <div slot="successRate" slot-scope="text, record">
        <div>
          <a-tag color="green" v-if="record.rate >= 1">
            {{ `${ (record.rate * 100).toFixed(2) }%` }}
          </a-tag>
          <a-tag color="red" v-else>
            {{ `${ (record.rate * 100).toFixed(2) }%` }}
          </a-tag>
        </div>
      </div>
    </a-table>
    <div class="top"><span class="KPI_name">经销商KPI</span> <span
        class="time">({{ dealersData.dealer_count || 0 }}位经销商，{{ dealersData.indicator_count || 0 }}个指标)</span></div>
    <a-table
        :columns="dealersColumnsList"
        :data-source="dealersList"
        :pagination="false"
        :rowKey="(record, index) => index"
    >
      <div slot="dealerInfo" slot-scope="text, record">
        <div class="row-box box-1">
          <div><span>经销商名称：</span></div>
          <div>{{ record.dealer_name || "--" }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>经销商ID：</span></div>
          <div>{{ record.dealer_id || "--" }}</div>
        </div>
      </div>
      <div slot="KPI_rate" slot-scope="text, record">
        <div class="row-box box-2" v-for="(d,index) in record.indicators" :key="index">
          <div><span>{{ d.indicator_name }}：</span></div>
          <div style="display: flex;align-items: center">
            <span>{{ d.count }}/{{ d.reach_count }}</span>
            <div>
              <a-tag color="green" style="margin-left: 10px" v-if="(d.count / d.reach_count) >= 1">
                {{ `${ ((d.count / d.reach_count) * 100).toFixed(2) }%` }}
              </a-tag>
              <a-tag color="red" style="margin-left: 10px" v-else>
                {{ `${ ((d.count / d.reach_count) * 100).toFixed(2) }%` }}
              </a-tag>
            </div>
          </div>
        </div>
      </div>
      <div slot="KPI_rate_author" slot-scope="text, record">
        <div v-if="record.reach_author.length > 0">
          <div v-for="(s,index) in record.reach_author" :key="index">
            <div class="row-box box-1">
              <div><span>账</span><span>号</span><span>名</span><span>称：</span></div>
              <div>{{ s.nick_name || "--" }}</div>
            </div>
            <div class="row-box box-1">
              <div><span>平</span><span>台：</span></div>
              <div>{{ s.platform || "--" }}</div>
            </div>
            <div class="row-box box-1">
              <div><span>平</span><span>台</span><span>账</span><span>号：</span></div>
              <div>{{ s.code || "--" }}</div>
            </div>
            <div class="row-box box-1">
              <div><span>达</span><span>标</span><span>KPI：</span></div>
              <div><span v-for="(a,index) in s.reach_indicators" :key="index">{{ `${ a },` }}</span></div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="row-box box-1">
            <div><span>账</span><span>号</span><span>名</span><span>称：</span></div>
            <div>--</div>
          </div>
          <div class="row-box box-1">
            <div><span>平</span><span>台：</span></div>
            <div>--</div>
          </div>
          <div class="row-box box-1">
            <div><span>平</span><span>台</span><span>账</span><span>号：</span></div>
            <div>--</div>
          </div>
          <div class="row-box box-1">
            <div><span>达</span><span>标</span><span>KPI：</span></div>
            <div>--</div>
          </div>
        </div>
      </div>
    </a-table>


    <!-- 已选择的模板-->
    <a-modal v-model="listModal" :footer="null" width="40%" title="已选择模板" @cancel="handlelistModalCancel">
      <div class="div_moduleName">
        <p v-for="item in module_name_list">{{ item.moduleName }}</p>
      </div>
    </a-modal>
  </div>
</template>

<script>
import moment from "moment";
import https from "@/api/kpi.js";


export default {
  name: "kpiPage",
  data() {
    return {
      dealersColumnsList: [
        {
          dataIndex: "dealer_name",
          scopedSlots: {customRender: "dealerInfo"},
          title: "经销商信息"
        },
        {
          dataIndex: "KPI_rate",
          scopedSlots: {customRender: "KPI_rate"},
          title: "KPI达标率"
        },
        {
          dataIndex: "KPI_rate_author",
          scopedSlots: {customRender: "KPI_rate_author"},
          title: "KPI达标帐号"
        }
      ],
      activityColumnsList: [
        {
          dataIndex: "kpi_name",
          title: "KPI名称"
        },
        {
          dataIndex: "kpi_value",
          title: "KPI值"
        },
        {
          dataIndex: "current_value",
          title: "当前值"
        },
        {
          dataIndex: "successRate",
          scopedSlots: {customRender: "successRate"},
          title: "达标率"
        }
      ],
      end_time: "",
      start_time: "",
      modal_checked: true,
      listModal: false,
      open_type: 0,
      selectedRowKeys: [],
      module_name_list: [],
      dealersData: {},
      kpiInfo: {},
      dealersList: [],
      kpiValue: [],
      moduleIds: []
    };
  },
  props: {
    kpiId: {
      type: [Number, String]
    }
  },
  mounted() {
    this.getActivityList();
    this.getDealersList();
    // console.log(this.kpiInfo);
    // this.selectedRowKeys = this.moduleIds;
    // this.getModuleMameList();

  },
  methods: {
    getDealersList() {
      let params = {
        kpi_id: this.kpiId
      };
      https.getDealersList(params).then(res => {
        if(res.code === 0) {
          this.dealersData = res.data || {};
          this.dealersList = res.data.dealer_kpis || [];
        } else {
          this.$message.error(res.msg || "获取经销商KPI详情失败");
        }
      });
    },
    getActivityList() {
      let params = {
        kpi_id: this.kpiId
      };
      https.getActivityList(params).then(res => {
        if(res.code === 0) {
          this.moduleIds = res.data.kpi_info.module_ids || [];
          this.selectedRowKeys = res.data.kpi_info.module_ids || [];
          this.open_type = this.selectedRowKeys.length ? 1 : 0;
          this.kpiInfo = res.data.kpi_info || {};
          this.kpiValue = res.data.kpi_value || [];
          this.end_time = moment(res.data.kpi_info.end_time).format("YYYY-MM-DD");
          this.start_time = moment(res.data.kpi_info.start_time).format("YYYY-MM-DD");
          this.getModuleMameList();
        } else {
          this.$message.error(res.msg || "获取活动KPI详情失败");
        }
      });
    },
    getModuleMameList() {
      let ids = Array.isArray(this.moduleIds) ? this.moduleIds.map(item => item).join(",") : "";
      https.getModuleMameList(ids).then(res => {
        if(res.code == "200") {
          this.selectedRows = res.data || [];
        } else {
          this.selectedRows = [];
          this.$message.error(res.message || "获取模板列表失败");
        }
      });
    },
    handelBtn() {
      this.listModal = true;
      this.module_name_list = this.selectedRows;
    },
    handlelistModalCancel() {
      this.listModal = false;
      this.module_name_list = [];
    },
  }
};
</script>

<style scoped lang="less">
.top {
  padding-bottom: 20px;
  padding-top: 40px;

  .name {
    font-size: 20px;
    font-weight: bold;
    padding-right: 10px;
  }

  .time {
    color: #c2c0c0;
  }

  .KPI_name {
    font-size: 14px;
    font-weight: bold;
    padding-right: 4px;
  }
}

.row-box {
  display: flex;
  margin-bottom: 5px;

  & > div:first-child {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-weight: bold;

    .title {
      display: flex;
      justify-content: space-between;
    }
  }

  & > div:last-child {
    flex: 1;
    min-width: 50px;
  }
}

.box-1 {
  & > div:first-child {
    width: 100px;
  }
}

.box-2 {
  & > div:first-child {
    width: 170px;
  }
}

.div_moduleName {
  max-height: 600px;
  overflow-y: auto;
}
</style>
