<template>
  <div>
    <kpiPage
        ref="KPITab"
        tabs="KPI"
        :kpiId="kpi_id"
    ></kpiPage>
  </div>
</template>

<script>
import kpiPage from "./components/kpiPage";


export default {
  name: "kpi_detail",
  components: {
    kpiPage
  },
  data() {
    return {
      activity_id: this.$route.query.activity_id || "",
      principal_id: this.$route.query.principal_id || "",
      kpi_id: this.$route.query.id || "",
    };
  },
};
</script>

<style scoped>

</style>
